import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import logo from '../../src/images/Alfayez_logo.png'
import footer from '../../src/images/footer.png'
import QRCode from '../../src/images/static-qr-code-0425d8f5bd475d78f3e5f42ff063b7c5.svg'
import femaleicon from '../../src/images/femaleuser.svg'
import maleicon from '../../src/images/maleuser.svg'
import whiteicon from '../../src/images/whiteusericon.svg'
import man from '../../src/images/man.png'
import woman from '../../src/images/woman.png'
import globe from '../../src/images/globe.svg'
import download from '../../src/images/download.svg'
import dTree from 'd3-dtree';
import '../../src/App.css'
import { useReactToPrint } from 'react-to-print';
import { api_url } from '../vaiable';
function Home() {
  const [hideTreeDiv, setHideTreeDiv] = useState('complete')
  const [timeStamp, setTimeStamp] = useState(Date.now())
  const reset = () => setTimeStamp(Date.now())
  const hideTree = (treetype) => {
    setHideTreeDiv(treetype);
    if (treetype != hideTreeDiv) {
      reset()
    }
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customHeader = queryParams.get('idToken');


  const removeEmptyArrays = (obj) => {
    // Recursive function to traverse the object and remove empty arrays
    const removeEmptyArraysAndNullsRecursive = (data) => {
      if (Array.isArray(data)) {
        // Filter out non-empty arrays and null values
        return data.filter((item) => {
          if (Array.isArray(item)) {
            return item.length > 0;
          } else {
            return item !== null;
          }
        }).map(removeEmptyArraysAndNullsRecursive);
      } else if (typeof data === 'object' && data !== null) {
        // For objects, recursively remove empty arrays and null values from values
        const result = {};
        for (const key in data) {
          const value = removeEmptyArraysAndNullsRecursive(data[key]);
          if ((Array.isArray(value) && value.length === 0) || value === null) continue;
          result[key] = value;
        }
        return result;
      }
      return data;
    };
    // Call the recursive function to remove empty arrays
    return removeEmptyArraysAndNullsRecursive(obj);
  };

  const getTreeData = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Authorization': `Bearer ${customHeader}`, // Set the Authorization header with the token
      },
    };
    fetch(`${api_url}tree?name=` + hideTreeDiv, requestOptions)
      .then(response => response.json())
      .then(result => {
        let Tree = [result.data];
        const updatedJsonObject = removeEmptyArrays(Tree);
        console.log(updatedJsonObject);
        dTree.init(updatedJsonObject, {
          target: "#graph",
          debug: true,
          height: 1200,
          width: 1400,
          nodeWidth: 200,
          callbacks: {
            textRenderer: function (name, extra, textClass, nodeClass) {
              // THis callback is optinal but can be used to customize
              // how the text is rendered without having to rewrite the entire node
              // from screatch.
              if (extra && extra.nickname)
                name = name;
              let nodeC = nodeClass?.slice(0, 4);
              let image = (nodeC == 'Male' ? man : woman)
              let icon = (nodeC == 'Male' ? maleicon : femaleicon)

              return "<div style='text-align:right;padding:3px;' class='" + textClass + "'>"
                + "<span>" + extra?.id_number + "</span>"
                + "<img style='margin:0px 5px;' class='hovericon icon' src='" + whiteicon + "' width='12' height='12' alt='logo'>"
                + "<img style='margin:0px 5px;' class='icon normalicon' src='" + icon + "' width='12' height='12' alt='logo'>"
                + "<div style='display: flex;justify-content:right;align-items:center;margin-right: 2px;margin-top: 5px;'><div style='text-align: right;margin-right:5px;'>"
                + "<h3>" + name + "</h3>" +
                "<span>" + extra?.dob + "</span>"
                + "</div><div>"
                + "<img src='" + image + "' width='30' class='profileimage' height='30' style='border-radius: 50%;' alt=''>"
                + "</div></div></div>";
            },
            nodeRenderer: function (name, x, y, height, width, extra, id, nodeClass, textClass, textRenderer) {
              // This callback is optional but can be used to customize the
              // node element using HTML.
              let node = '';
              node += '<div ';
              node += 'class="' + nodeClass + '" ';
              node += 'id="node' + id + '">\n';
              node += textRenderer(name, extra, textClass, nodeClass);
              node += '</div>';
              return node;
            },
          }
        });
      })
      .catch(error => {
      });
  }

  const handlePrintSummary = useReactToPrint({
    content: () => printRef.current,
  });

  const printRef = useRef(null)
  useEffect(() => {
    getTreeData()
  }, [hideTreeDiv]);
  return (
    <>

      <div className='navbar'>
        <div className='nav-container'>
          <div className='nav-left'>
            <button type='button' className='btn-complete-tree btn' onClick={() => hideTree('complete')}>Complete Tree</button>
            <button type='button' className='btn btn-male-tree' onClick={() => hideTree('male')}>Male Tree</button>
            <button type='button' className='btn btn-female-tree' onClick={() => hideTree('female & id=8')}>Female Tree</button>
          </div>
          {/* <div className='nav-right'>
            <div className="nav-burger">
              <span>icon</span>
            </div>
            <button type='button' className='btn btn-print-tree' onClick={() => handlePrintSummary()}><img src={download} alt="logo" style={{ marginRight: '3px', height: '13px' }} /> Export</button>
          </div> */}
        </div>
      </div>

      <div className="App" ref={printRef}>
        {/* <header className="header">
          <img src={logo} className='logo' alt="logo" />
          <div style={{ marginRight: '5px' }}>
            <h1 className='medium-font'>شجرة عائلة الفايز، مرجع عام لآسرة آل فايز الناصري التميمي</h1>
            <h1 className='large-font'>تطبيق عائلة الفايز</h1>
          </div>
        </header> */}
        <main id='main-container' >
          <div id="graph" key={timeStamp} ></div>
        </main>
        {/* <div className='footer' style={{ backgroundImage: "url(" + footer + ")" }}>
          <div className='footer-container'>
            <div className='app-link-conatiner QRcode-conatiner' style={{ textAlign: 'center', display: 'flex', alignItems: 'flex-end' }}>
              <div>
                <img src={QRCode} className='QRcode' alt="logo" />
                <a href="https://download.alfayez.app" style={{ color: 'black' }}><h1 className='small-font'>download.alfayez.app</h1></a>
              </div>
            </div>
            <div className='app-link-conatiner' style={{ display: 'flex', alignItems: 'flex-end' }}>
              <a href="https://www.alfayez.app" style={{ color: '#737D67' }}><h1 className='small-font' style={{ display:'flex',justifyContent:'center',alignItems:'center' }}><img className='globe' src={globe} alt="logo" style={{ marginRight: '8px', height: '28px' }} /> https://www.alfayez.app</h1></a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Home;



