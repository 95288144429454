import React, { Component, Suspense } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Home from './view/Home'
import Family from './view/Family'
import News from './view/News'
 

class App extends Component {
  render() {
    return (
      <BrowserRouter basename='/'>
        <Suspense  >
          <Routes>
            <Route path="/" name="Home" exact element={<Home />} />
            <Route path="/specific-family-tree/:id"  name="Family" element={<Family />} />
            <Route path="/news/:id"  name="News" element={<News />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
