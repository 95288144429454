import React, { useState, useEffect, useRef } from 'react';
function News() {

  return (
    <>
      <div style={{ width: '80%', margin: 'auto' }}>
        <h1>Privacy Policy for Alfayez</h1>
        <p>Effective Date: 15 March 2023</p>
        <p>Thank you for visiting Alfayez's website (Alfayez.pro). We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose information about you when you visit our website or use our services.</p>

        <h2>1. Information We Collect</h2>
        <p>We collect information you provide directly to us when you use our website, register for an account, or communicate with us. The information we collect may include your name, email address, phone number, company name, and any other information you choose to provide.</p>
        <p>We also automatically collect certain information when you visit our website, such as your IP address, browser type, device type, and operating system.</p>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our website. We may also use your information for research and analytics purposes.</p>
        <p>We do not sell or rent your personal information to third parties. However, we may share your information with our service providers who perform services on our behalf, such as website hosting, data analysis, and customer service.</p>
        <p>We may also disclose your information to comply with applicable laws or regulations, or to respond to a subpoena, court order, or other lawful request for information we receive.</p>

        <h2>3. Cookies and Similar Technologies</h2>
        <p>We use cookies and similar technologies to collect information about your use of our website. Cookies are small text files that are stored on your device when you visit a website. They allow us to remember your preferences and customize your experience on our website.</p>
        <p>You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some features of our website.</p>

        <h2>4. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites that are not owned or controlled by Alfayez. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>

        <h2>5. Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

        <h2>6. Children's Privacy</h2>
        <p>Our website is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.</p>

        <h2>7. Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at contact@Alfayez.pro.</p>
      </div>
    </>
  );
}

export default News;



